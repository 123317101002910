import React from "react";

const FeatureContent = [
  {
    meta: "Pinky Promise ",
    subTitle: `We will never keep you on our app longer than you absolutely have to be there.`,
  },


];

const FeatureFour = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
        >
          <div className="block-style-five" style={{ pointerEvents: 'none' }}>
            <h6 className="title" style={{ marginTop: '-20px' }}>
              <span>{val.meta}</span>
            </h6>
            <p style={{ whiteSpace: "pre-wrap" }}>{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureFour;