import React from "react";
// import { VideoTag } from "react-video-tag";
// import videoFile from '../assets/welcome.mp4';
import appPic from '../assets/app.jpg';

const Video = () => {
  return (
    <>
      {/* <VideoTag
        autoPlay={true}
        muted={true}
        playsInline={true}
        loop={true}
        src={videoFile}
      /> */}
      <img src={appPic} alt="MingleSea" />
    </>
  );
};

export default Video;
