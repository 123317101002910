import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingEditor from "components/header/landing/HeaderLandingEditor";
import ContactAddressTwo from "../../../../components/contact/address/ContactAddressTwo";
//import ContactForm from "../../../../components/contact/form/ContactForm";
//import FooterSeven from "components/footer/FooterSeven";
import CopyRightThree from "components/footer/CopyRightThree";

const ContactProjectManagement = () => {
  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>
          Contact Us
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingEditor />
      {/* End Header */}

      {/* =============================================
				Fancy Hero One
			==============================================  */}
      <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-11 m-auto">
              {/* <div className="page-title">Contact us</div> */}
              <h2 className="font-rubik" style={{fontSize:'50px'}}>
                Contact Us
              </h2>
            </div>
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* /.fancy-hero-one */}

      {/* 	=============================================
				Contact Us Light
			==============================================  */}
      <div className="contact-us-light pt-140 pb-200 md-pt-90 md-pb-80">
        {/* <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div> */}
        <div className="container">
          <ContactAddressTwo />
          {/* <div className="form-style-light">
            <ContactForm />
          </div> */}
        </div>
      </div>
      {/* /.contact-us-light */}

      {/* 	=====================================================
				Footer Style One
			===================================================== */}
      <footer className="theme-footer-seven mt-20 md-mt-0">
        <div className="lg-container">
          {/* <div className="container">
            <FooterSeven />
          </div> */}

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default ContactProjectManagement;
