import React from "react";
// import { Link } from "react-router-dom";

import { getEnvVars } from '../../../src/env';
const ENV = getEnvVars(process.env.REACT_APP_ENV);


const FeaturesContent = [
  {
    bgColor: "#d1feff",
    icon: "115",
    title: "Pay Per Match (PPM)",
    desc: `The only way to make sure our interests are aligned with our users was to charge our male users only and only after a match. $${ENV.REACT_APP_MATCH_QUOTA_VALUE_USD}.00 USD per match.`,
    dataDealy: "0",
  },

];

const FancyFeatureTenOne = () => {
  return (
    <div className="row justify-content-center mt-35 md-mt-20">
      {FeaturesContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos-delay={val.dataDealy}
          key={i}
        >
          <div className="block-style-fifteen" style={{ pointerEvents: 'none' }}>
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: val.bgColor, paddingTop:'8px' }}
            >
             <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h3>{val.title}</h3>
            <p style={{ whiteSpace: "pre-wrap" }}>{val.desc}</p>

          </div>
          {/* /.block-style-ten */}
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureTenOne;
