import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HeaderLandingEditor from "components/header/landing/HeaderLandingEditor";
// import Footer from "../../../components/footer/Footer";
import CopyRightThree from "components/footer/CopyRightThree";
import { Link } from "react-router-dom";

import { getEnvVars } from '../../../env';
const ENV = getEnvVars(process.env.REACT_APP_ENV);


const RosContent = [
  {
    title: `${ENV.REACT_APP_NINETY_DAYS_DELETE} days`,
    description: `In MingleSea an account can last for a maximum of ${ENV.REACT_APP_NINETY_DAYS_DELETE} days. After your first successful profile verification, you will have exactly ${ENV.REACT_APP_NINETY_DAYS_DELETE} days to find someone on MingleSea. When your ${ENV.REACT_APP_NINETY_DAYS_DELETE} days are gone, your account will be scheduled for deletion in ${ENV.REACT_APP_DELETED_DELETE} days time, during which you cannot rejoin or use MingleSea.`
  },
  {
    title: "PsssLike",
    description: `In MingleSea, users don't swipe. In here, users "PassLike". What's the difference? There is a fixed, daily limit, and you can't buy more PassLikes or get them in any other way.`
  },
  {
    title: `Limits`,
    description: `Every 24 hours, you get a total of ${ENV.REACT_APP_DAILY_TOTAL_PASS_LIKE_REPORT} PassLikes which includes ${ENV.REACT_APP_DAILY_TOTAL_LIKE} likes.`,
  },
  {
    title: `Free of charge`,
    description: `MingleSea is, and will always remain 100% free for Women. If you are a Man, you need have "Match Quota" to PassLike. There is only one reload option for getting more match quotas and that is $${ENV.REACT_APP_MIN_RELOAD_USD}.00 USD for 10 match quotas.`,
  },
  {
    title: `MingleAI`,
    description: `MingleAI is our state of the art AI model which we use to hand select the magical suggestions we show you, one by one.`,
  },
  {
    title: `Match Quota`,
    description: `1 Match Quota is $${ENV.REACT_APP_MATCH_QUOTA_VALUE_USD}.00 USD. If you are a man and you don't have any match quotas, not only you can't PassLike, your profile will also be hidden from all women.`,
  },
  {
    title: `Active match limit`,
    description: `If you are a Male, you can have maximum of 2 active matches at any given point in time and if you are a Female, you can have maximum of 1 active match at any given point in time. An active match means a match with a running clock.`,
  },
  {
    title: `Active Hide`,
    description: `When you reach your Active Match Limit which is 2 for men and 1 for women, we hide your profile from other users because we hope that was a match made in heaven so you and your match leave MingleSea and never come back.`,
  },
  {
    title: `After you match`,
    description: `When you match with someone, you will have exactly 24 hours to chat with them and hopefully continue in another app. After the 24 hours, they will forever disappear from your matches page.`,
  },
  {
    title: `Chat limit`,
    description: `In the 24 hours that you can chat with your match, you can only send her/him ${ENV.REACT_APP_MESSAGES_PER_CHAT} messages, and each message can be a maximum 100 characters long.`,
  },
  {
    title: `Chat privacy for men`,
    description: `Only the lady you are chatting with will see you are online. If you have two active matches, the person you are not chatting with will not know you are online and chatting with the other person.`,
  },
  {
    title: `Verification photo rules`,
    description: `You must follow the pose shown to you exactly. No one can see your profile before you pass your selfie verification.`,
  },
  {
    title: `Profile photo rules`,
    description: `1. You must provide 3 distinct photos. 
2. The person in the photos must match the verification photo.
3. You can't include any type pf ID, phone number, handle, or any other type of contact information in your phtos.
4. Your photos can't contain any text. Don't be smart and write bio in a photo.
5. Your photos can't contain anything violent or repulsive.
6. Your photos can't contain anything sexual.
7. Your face must be visible in all of your photos, and must match the person in the verification photo.
8. No one can see your profile before you pass your profile photo verification.`,
  },
  {
    title: `Verification limit`,
    description: `Whenever you upload a verificaion or profile photo, both the verification photo and all of your profile photos are reviewed. If we reject either your verification photo or any of your profile photos ${ENV.REACT_APP_MAX_USER_VERIFICATION_ATTEMPT_BY_MODERATOR} times in a row, your account will be blocked for ${ENV.REACT_APP_BLOCKED_DELETE} days and will be deleted after that. You can register again after your account is deleted.`,
  },
  {
    title: `Inactive Hide`,
    description: `If you don't use MingleSea ${ENV.REACT_APP_INACTIVE_HIDE} days or longer, we will stop showing your profile to other users.`,
  },
  {
    title: `Blocked User`,
    description: `If we block your account for whatever reason, the decision is final and there is no appeal process. However, your account will be deleted ${ENV.REACT_APP_BLOCKED_DELETE} days after it was blocked and you can register again.`,
  },
  {
    title: `Deleting your account`,
    description: `If you choose to delete your account, it will be scheduled for deletion in ${ENV.REACT_APP_DELETED_DELETE} days time and the decision is irreversible. During that time you can't use the same email or phone number to register a new account.`,
  },
  {
    title: `Account Ban`,
    description: `There is no permanent ban on MingleSea.`,
  },
  {
    title: `Shadow Ban`,
    description: `There is no shadow ban on MingleSea. If  you're allowed to use the app, then you're allowed to use the app. No ifs, no buts.`,
  },
  {
    title: `Sort, Categorize, Rank, etc.`,
    description: `Messing with our users is not how we make money. Our business model is PPM (Pay Per Match). We only make money when you get that magical match.`,
  }
];

const Ros = () => {
  const [filteredContent, setFilteredContent] = useState(RosContent);

  const searchQuestion = (event) => {
    const searchText = event.target.value.toLowerCase();
    const filteredQuestions = RosContent.filter(item =>
      item.title.toLowerCase().includes(searchText) ||
      item.description.toLowerCase().includes(searchText)
    );
    setFilteredContent(filteredQuestions);
  };


  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>ROS</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingEditor />
      {/* End Header */}

      {/* =============================================
            Fancy Hero One
        ==============================================  */}
      <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 m-auto">
              <h2 style={{ fontSize: '40px' }}>Rules Of The Sea</h2>
              <p className="sub-heading" style={{ fontSize: '23px', marginBottom: '10px' }}>
                How MingleSea app works
              </p>
              <div className="update-date">LAST UPDATED: 2024/06/01</div>
            </div>
          </div>
          <form onSubmit={(event) => { event.preventDefault(); event.target.querySelector('input').blur(); }} className="search-form">
            <input onChange={searchQuestion} type="text" placeholder="Search for rules here ..." />
            {/* <button>
              <img src="images/icon/47.svg" alt="icon" />
            </button> */}
          </form>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* /.fancy-hero-one */}

      {/* =============================================
            FAQS
        ==============================================  */}
      <div className="faqs-inner-page">
        <img
          src="images/shape/66.svg"
          alt="shape"
          className="shapes shape-one"
        />
        {/* <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div> */}
        <div className="container">
          <div className="row">
            <div className="col-xl-11 m-auto">
              {/* <div className="faqs-header pb-60 md-pb-50">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="d-flex">
                      <img
                        src="images/icon/48.svg"
                        alt="icon"
                        className="icon"
                      />
                      <div className="md-mb-30">
                        <h3>FAQs</h3>
                        <p className="collection-preview">
                          Selling tickets for a raffle, lottery or sweepstake is
                          prohibited on Eventsmatic.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="avatar d-flex align-items-center">
                      <img src="images/media/img_41.png" alt="media" />
                      <img src="images/media/img_42.png" alt="media" />
                      <span className="avatar_image avatar_fallback font-rubik">
                        +1
                      </span>
                    </div>
                    <p className="collection-info">
                      <span>63 articles</span> in this collection <br /> Written
                      by Jonny White, Emily Coddington and <span>1</span> other
                    </p>
                  </div>
                </div>
              </div> */}
              {/* /.faqs-header */}

              <div className="all-faqs" style={{ marginTop: '-50px', marginBottom: '-100px' }}>
                <div className="faqs-all-qus">
                  {filteredContent.map((item, i) => (
                    <Link
                      className="article-preview d-flex"
                      // to="/faq-details"
                      key={i}
                      // data-aos="fade-up"
                      // data-aos-duration="1200"
                      style={{ pointerEvents: 'none' }}
                    >
                      {/* <img
                        src={`images/media/${item.avatar}.png`}
                        alt="media"
                        className="avatar-img"
                      /> */}
                      <div>
                        <h3 className="font-rubik" style={{ color: "#4f4f4f" }}>{item.title}</h3>
                        <hr></hr>
                        <div className="avatar-info">
                          <p style={{ color: "#000", marginTop: '5px', whiteSpace: "pre-wrap" }}>{item.description}</p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
                {/* /.faqs-all-qus  */}
              </div>
              {/*  /.all-faqs */}

              {/* <div
                className="text-center more-faq-ask"
              // data-aos="fade-up"
              // data-aos-duration="1200"
              // data-aos-delay="100"
              >
                <h3 className="mb-35 font-rubik">Don’t find your answer?</h3>
                <Link to="/contact">
                  <a href="" className="theme-btn-one">
                    Contact us
                  </a>
                </Link>
              </div> */}
              {/* End more-faq-ask */}
            </div>
          </div>
        </div>
      </div>
      {/* /.faqs-inner-page */}

      <footer className="theme-footer-seven mt-20 md-mt-0">
        <div className="lg-container">
          {/* <div className="container">
            <FooterSeven />
          </div> */}

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default Ros;
