import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HeaderLandingEditor from "components/header/landing/HeaderLandingEditor";
// import Footer from "../../../components/footer/Footer";
import CopyRightThree from "components/footer/CopyRightThree";
import { Link } from "react-router-dom";

import { getEnvVars } from '../../../env';
const ENV = getEnvVars(process.env.REACT_APP_ENV);


const FaqContent = [
  {
    question: `Can you please share some reasons why I shouldn’t join MingleSea?`,
    answer: `Absolutely.
    
    1. You enjoy wasting time.
    2. You love wasting money.
    3. You find it delightful when you get unmatched out of nowhere.
    4. You appreciate getting shadow banned.
    5. You find chatting with people that have unverified profile interesting.
    6. You find it fascinating to wait for 3 days to get a reply to your message. 
    
    We can keep going ...`,
  },
  {
    question: `In which countries does MingleSea operate?`,
    answer: `For now only Singapore and Malaysia.`,
  },
  {
    question: `MingleSea seems to be offline. My internet is fine, I just checked. What's up?`,
    answer: `This is not something that heppens often. We're working on it. We will be back online soon. Please give us a little time. Thank you for your patience.`,
  },
  {
    question: `Does MingleSea have a web version?`,
    answer: `No.`,
  },
  {
    question: `Why is MingleSea so expensive?`,
    answer: `When you consider the monetary value of the time you waste on other dating apps, you'll find that we are actually very affordable.`,
  },
  {
    question: `What is the minimum reload amout for men to get more match quotas?`,
    answer: `We only have one option, and that is $${ENV.REACT_APP_MIN_RELOAD_USD}.00 USD.`,
  },
  {
    question: `Do women have to pay on MingleSea`,
    answer: `No.`,
  },
  {
    question: `Why am I not getting as many matches as I think I should?`,
    answer: `It's not us. Our business model is PPM (Pay Per Match), meaning the more matches you get, the more money we make. So it's really not us.`
  },
  {
    question: `Why is your moderation process taking so long?`,
    answer: `Each image you upload is reviewed by a real human before we clear it to be seen by other users.`,
  },
  {
    question: `Why are you reviewing my images again?`,
    answer: `Each image you upload is reviewed by a real human before we clear it to be seen by other users.`,
  },
  {
    question: `Can I change my password?`,
    answer: `Yes. Please logout and follow "Reset Password" steps in the login page.`,
  },
  {
    question: `Can I change my email address or phone number?`,
    answer: `No.`,
  },
  {
    question: `I don't have access to my email, can you help me with password recovery?`,
    answer: `No.`,
  },
  {
    question: `I don't have access to my phone number, can you help me with password recovery?`,
    answer: `No.`,
  },
  {
    question: `Why did you reject my photos?`,
    answer: `Because you didn't follow our guidelines.`,
  },
  {
    question: `I followed all of your guidelines but you still rejected my photos. Why?`,
    answer: `Not everyone gets in. We reserve the right to refuse you service without providing you with any explenation. Please read our Terms and Conditions carefully.`,
  },
  {
    question: `I followed all of your guidelines but you rejected my photos and blocked me after the moderation. Why?`,
    answer: `Not everyone gets in. We reserve the right to refuse you service without providing you with any explenation. Please read our Terms and Conditions carefully.`,
  },
  {
    question: `My photos were rejected. Can I appeal this decision?`,
    answer: `No.`,
  },
  {
    question: `Can I unmatch any of my matches?`,
    answer: `No.`,
  },
  {
    question: `Can I report someone?`,
    answer: `Yes, but, you can only report a profile. You cannot report a match. You cannot report a chat.`,
  },
  {
    question: `Someone was repulsive to me in the chat and I reported them via email. What happens now?`,
    answer: `Absolutely nothing. Wait for 24 hours and they will disappear from your matches page.`,
  },
  {
    question: `Can I get a refund?`,
    answer: `No. Regardless of the situation, we will NOT issue a refund. Please read our Terms and Conditions carefully.`,
  },
  {
    question: `Why can't I mention my name on my profile?`,
    answer: `We don't think it is necessary.`,
  },
  {
    question: `Why can't I write a bio on my profile?`,
    answer: `There is enough information that are compulsory to provide. A bio would not be necessary.`,
  },
  {
    question: `How many times can I fail selfie and profile verification?`,
    answer: `${ENV.REACT_APP_MAX_USER_VERIFICATION_ATTEMPT_BY_MODERATOR} times. After ${ENV.REACT_APP_MAX_USER_VERIFICATION_ATTEMPT_BY_MODERATOR} consecutive failed attempts your account will be blocked, and it will be deleted ${ENV.REACT_APP_BLOCKED_DELETE} days after you have been blocked. You can register again when your account is deleted.`,
  },
  {
    question: `Can I buy more PassLikes?`,
    answer: `No.`,
  },
  {
    question: `Can I get more active matches? I want to chat with more than 2 people at a time.`,
    answer: `No.`,
  },
  {
    question: `Can I extend the time for my active matches?`,
    answer: `No.`,
  },
  {
    question: `My active match ran out of time. Can I get it back?`,
    answer: `No.`,
  },
  {
    question: `I ran out of messages to send to my match. Is there any way I can send more messages?`,
    answer: `No.`,
  },
  {
    question: `I moved to a new country and MingleSea is available in the new country, but I still can't see anyone. why?`,
    answer: `The phone number you used in the registration process must be from the new country. In other words, you need to create a new account using a phone numebr from the new country.`,
  },
  {
    question: `Can I ask you for my data?`,
    answer: `Yes. You will need to email us a copy of your IC and a selfie so we can verify it is you. It may take us up to 30 days to process your information and we will send you your data via email. `,
  },
  {
    question: `My daughter, my son, my husband, my wife, my dad, my mom, my uncle, my aunt, you get the idea, 
    I think/know the're on MingleSea. Can I ask you to give me their data?`,
    answer: `No. Please read our Terms and Conditions carefully.`,
  },
  {
    question: `Will I receive my data if I delete my account while you are processing my request to send me my data?`,
    answer: `No.`,
  },
  {
    question: `Can I delete my account?`,
    answer: `Yes. login to the app and use the delete button at the bottom of your profile page to delete your account. `,
  }
];

const Faq = () => {
  const [filteredContent, setFilteredContent] = useState(FaqContent);

  const searchQuestion = (event) => {
    const searchText = event.target.value.toLowerCase();
    const filteredQuestions = FaqContent.filter(item =>
      item.question.toLowerCase().includes(searchText) ||
      item.answer.toLowerCase().includes(searchText)
    );
    setFilteredContent(filteredQuestions);
  };

  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>FAQ</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingEditor />
      {/* End Header */}

      {/* =============================================
            Fancy Hero One
        ==============================================  */}
      <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 m-auto">
              <h2 className="font-rubik" style={{ fontSize: '50px' }}>FAQ</h2>
              <p className="sub-heading">
                It's either here or in ROS
              </p>
            </div>
          </div>
          <form onSubmit={(event) => { event.preventDefault(); event.target.querySelector('input').blur(); }} className="search-form">
            <input onChange={searchQuestion} type="text" placeholder="Search your question here ..." />
            {/* <button>
              <img src="images/icon/47.svg" alt="icon" />
            </button> */}
          </form>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* /.fancy-hero-one */}

      {/* =============================================
            FAQS
        ==============================================  */}
      <div className="faqs-inner-page">
        <img
          src="images/shape/66.svg"
          alt="shape"
          className="shapes shape-one"
        />
        {/* <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div> */}
        <div className="container">
          <div className="row">
            <div className="col-xl-11 m-auto">
              {/* <div className="faqs-header pb-60 md-pb-50">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="d-flex">
                      <img
                        src="images/icon/48.svg"
                        alt="icon"
                        className="icon"
                      />
                      <div className="md-mb-30">
                        <h3>FAQs</h3>
                        <p className="collection-preview">
                          Selling tickets for a raffle, lottery or sweepstake is
                          prohibited on Eventsmatic.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="avatar d-flex align-items-center">
                      <img src="images/media/img_41.png" alt="media" />
                      <img src="images/media/img_42.png" alt="media" />
                      <span className="avatar_image avatar_fallback font-rubik">
                        +1
                      </span>
                    </div>
                    <p className="collection-info">
                      <span>63 articles</span> in this collection <br /> Written
                      by Jonny White, Emily Coddington and <span>1</span> other
                    </p>
                  </div>
                </div>
              </div> */}
              {/* /.faqs-header */}

              <div className="all-faqs" style={{ marginTop: '-50px' }}>
                <div className="faqs-all-qus">
                  {filteredContent.map((item, i) => (
                    <Link
                      className="article-preview d-flex"
                      // to="/faq-details"
                      key={i}
                      // data-aos="fade-up"
                      // data-aos-duration="1200"
                      style={{ pointerEvents: 'none' }}
                    >
                      {/* <img
                        src={`images/media/${item.avatar}.png`}
                        alt="media"
                        className="avatar-img"
                      /> */}
                      <div>
                        <h3 className="font-rubik" style={{ color: "#4f4f4f" }}>{item.question}</h3>
                        <hr></hr>
                        <div className="avatar-info">
                          <p style={{ color: "#000" }}>{item.answer}</p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
                {/* /.faqs-all-qus  */}
              </div>
              {/*  /.all-faqs */}

              <div
                className="text-center more-faq-ask"
              // data-aos="fade-up"
              // data-aos-duration="1200"
              // data-aos-delay="100"
              >
                <h3 className="mb-35 font-rubik">Don’t find your answer?</h3>
                <Link to="/contact" className="theme-btn-one">
                  Contact us
                </Link>
              </div>
              {/* End more-faq-ask */}
            </div>
          </div>
        </div>
      </div>
      {/* /.faqs-inner-page */}

      <footer className="theme-footer-seven mt-20 md-mt-0">
        <div className="lg-container">
          {/* <div className="container">
            <FooterSeven />
          </div> */}

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default Faq;
