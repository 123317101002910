import React from "react";
// import { Link } from "react-router-dom";

const FeaturesContent = [
  {
    bgColor: "#ffe6fc",
    icon: "68_1",
    title: "Premium subscriptions",
    desc: `Monthly premium subscriptions are proven not to work, and that's exactly why we came up with PPM.`,
    dataDealy: "0",
  },
  {
    bgColor: "#ffe6fc",
    icon: "67_1",
    title: "Hyping your profile",
    desc: `If the dating app interests are aligned with yours, there is no need for this feature, therefore we don't have it in MingleSea.`,
    dataDealy: "100",
  },
  {
    bgColor: "#ffe6fc",
    icon: "70_1",
    title: "Fancy Likes",
    desc: `We find this feature to be very manipulative and a cause of unoptimal matches. A like is a like. No fancy ones here.`,
    dataDealy: "200",
  },
  {
    bgColor: "#ffe6fc",
    icon: "71_1",
    title: "Message Before Match",
    desc: `It's a very abusive feature so we skipped this one too.`,
    dataDealy: "200",
  },
  {
    bgColor: "#ffe6fc",
    icon: "71_2",
    title: "Advertisement",
    desc: `We are not here to waste your time, so no ads on MingleSea.`,
    dataDealy: "200",
  },


];

const FancyFeatureTen = () => {
  return (
    <div className="row justify-content-center mt-35 md-mt-20">
      {FeaturesContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos-delay={val.dataDealy}
          key={i}
        >
          <div className="block-style-fifteen mt-40" style={{ pointerEvents: 'none' }}>
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: val.bgColor }}
            >
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h3>{val.title}</h3>
            <p style={{ whiteSpace: "pre-wrap" }}>{val.desc}</p>

          </div>
          {/* /.block-style-ten */}
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureTen;
