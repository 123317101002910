import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingEditor from "components/header/landing/HeaderLandingEditor";
//import Footer from "../../../../components/footer/Footer";
import CopyRightThree from "components/footer/CopyRightThree";
import ScrollspyNav from "react-scrollspy-nav";

const TermsConditions = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Privacy & Terms
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingEditor />
      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy" style={{ borderBottom: '0px' }}>
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#opt1">
                      1. Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt2">
                      2. Terms of Service
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link" href="#opt3">
                      3. Privacy policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt4">
                      4. Cookie policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt5">
                      5. Third Party Links
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt6">
                      6. GDPR
                    </a>
                  </li> */}
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content ">
                  <div id="opt1">
                    <h2 className="font-gilroy-bold">Privacy Policy</h2>
                    <div className="update-date">LAST UPDATED: 2024/06/01</div>
                    {/* <h3>1. Introduction</h3>
                    <p>
                      We have taken a number of steps to comply with the GDPR.
                      For more details about our compliance and how we handle
                      your data please check our latest legal documents below:
                    </p>
                    <ul>
                      <li>
                        <a href="#">Privacy policy</a>
                      </li>
                      <li>
                        <a href="#">Terms and conditions</a>
                      </li>
                    </ul>
                    <h3>2. About us</h3>
                    <p>
                      When you use Ticket Tailor for selling tickets and
                      collecting data from your attendees you are the “data
                      controller” of all your attendees’ data. We are a “data
                      processor”, which means that we won’t do anything with
                      your attendees’ data other than what you need us to in
                      order to provide our service. We won’t be sending your
                      attendees any marketing emails, sharing their data with
                      anyone and if you want us to delete the data, just ask.
                      It’s your data. 100
                    </p>
                    <h3>3. Accessing our Website</h3> */}
                    <p>
                      Your privacy is important to us. It is MingleSea's policy to respect your privacy and comply with
                      any applicable law and regulation regarding any personal information we may collect about you,
                      including via our MingleSea app on Android and iOS, MingleSea website, and/or any associated services.
                    </p>
                    <p>
                      Personal information is any information about you which can be used to identify you. This includes
                      information about you as a person which may include but is not limited to your name, address and date of birth, your devices, payment details,
                      and even information about how you use an app or online service.
                    </p>
                    <p>
                      In the event our app or website contains links to third-party sites and services, please be aware that those sites and
                      services have their own privacy policies. After following a link to any third-party content, you should read
                      their posted privacy policy information about how they collect and use personal information. This Privacy
                      Policy does not apply to any of your activities after you leave our app or website.
                    </p>
                    <h3>Information We Collect</h3>
                    <p>
                      Information we collect falls into one of two categories: 'voluntarily provided' information and
                      'automatically collected' information.

                      'Voluntarily provided' information refers to any information you knowingly and actively provide
                      us when using our app and its associated services.

                      'Automatically collected' information refers to any information automatically sent by your device
                      in the course of accessing our app and its associated services.
                    </p>
                    <h3>Log Data</h3>
                    <p>
                      When you access our servers via our app, we may automatically log the standard data provided by your device.
                      It may include your device's Internet Protocol (IP) address, your device type and version, your activity within
                      the app, time and date, and other details about your usage.

                      Additionally, when you encounter certain errors while using the app, we may automatically collect data about the
                      error and the circumstances surrounding its occurrence. This data may include technical details about your
                      device, what you were trying to do when the error happened, and other technical information relating to the
                      problem. You may or may not receive notice of such errors, even in the moment they occur, that they have occurred,
                      or what the nature of the error is.

                      Please be aware that while this information may not be personally identifying by itself, it may be possible to
                      combine it with other data to personally identify individual persons.
                    </p>
                    <h3>Device Data</h3>
                    <p>
                      Our app may access and collect data via your device's in-built tools, which may include but is not limited to:

                      Location data,
                      Camera,
                      Storage, photos and/or media,
                      Notifications,
                      Mobile data.

                      When you install our app or use your device’s tools within our app, we request permission to access this information.
                      The specific data we collect can depend on the individual settings of your device and the permissions you grant when
                      you install and use the app.
                    </p>
                    <h3>Personal Information</h3>
                    <p>
                      We may ask for personal information — for example, when you submit content to us or when you contact us —
                      which may include but is not limited to one or more of the following:

                      Email,
                      Phone/mobile number,
                      Age,
                      Height,
                      Star sign,
                      Drinking habits,
                      Smoking habits,
                      Drug use habits,
                      Exercise habits,
                      Yearly Income,
                      Number of kids,
                      Education level,
                      Religion,
                      Marital Status,
                      Dating purpose,
                      Political affiliation,
                      City of residence.
                    </p>
                    <h3>User-Generated Content</h3>
                    <p>
                      We consider 'user-generated content' to be materials (text, image and/or video content) voluntarily supplied
                      to us by our users for the purpose of publication on our app or re-publishing on our social media channels.
                      All user-generated content is associated with the account or email address and phone number used to submit the materials.

                      Please be aware that any content you submit for the purpose of publication will be public after posting
                      (and subsequent review or vetting process). Once published it may be accessible to third parties not
                      covered under this privacy policy.
                    </p>
                    <h3>Legitimate Reasons for Processing Your Personal Information</h3>
                    <p>
                      We only collect and use your personal information when we have a legitimate reason for doing so.
                      In which instance we only collect personal information that is reasonably necessary to provide our services to you.
                    </p>
                    <h3>Collection and Use of Information</h3>
                    <p>
                      We may collect personal information from you when you do any of the following on our app:
                      Register for an account,
                      Complete your profile in our app,
                      Use a mobile device or web browser to access our content,
                      Contact us via email, social media, or on any similar technologies,
                      When you mention us on social media,

                      We may collect, hold, use and disclose information for the following purposes,
                      and personal information will not be further processed in a manner that is incompatible with these purposes:

                      to provide you with our app's core features and services,

                      to enable you to customize or personalize your experience of our app,

                      to contact and communicate with you,

                      for analytics, market research, and business development, including to operate and improve our app,
                      associated applications, and associated social media platforms,

                      for advertising and marketing, including to send you promotional information about our products and
                      services and information about third parties that we consider may be of interest to you,

                      to enable you to access and use our app, associated platforms, and associated social media channels,

                      for internal record keeping and administrative purposes,

                      to comply with our legal obligations and resolve any disputes that we may have,

                      to attribute any content (e.g. posts and comments) you submit that we publish on our app,

                      for security and fraud prevention, and to ensure that our sites and apps are safe,
                      secure, and used in line with our terms of use,

                      for technical assessment, including to operate and improve our app, associated applications,
                      and associated social media platforms.

                      We may combine voluntarily provided and automatically collected personal information with general
                      information or research data we receive from other trusted sources. For example, If you consent to
                      us accessing your social media profiles, we may combine information sourced from those profiles with
                      information received from you directly to provide you with an enhanced experience of our app and services.
                    </p>
                    <h3>Security of Your Personal Information</h3>
                    <p>
                      When we collect and process personal information, and while we retain this information,
                      we will protect it within commercially acceptable means to prevent loss and theft, as
                      well as unauthorized access, disclosure, copying, use or modification.

                      Although we will do our best to protect the personal information you provide to us,
                      we advise that no method of electronic transmission or storage is 100% secure and no
                      one can guarantee absolute data security.

                      You are responsible for selecting any password and its overall security strength,
                      ensuring the security of your own information within the bounds of our services.
                      For example, ensuring any passwords associated with accessing your personal information
                      and accounts are secure and confidential.
                    </p>
                    <h3>How Long We Keep Your Personal Information</h3>
                    <p>
                      We keep your personal information only for as long as we need to. This time period may
                      depend on what we are using your information for, in accordance with this privacy policy.
                      For example, if you have provided us with personal information as part of creating an account
                      with us, we may retain this information for the duration your account exists on our system.
                      If your personal information is no longer required for this purpose, we will delete it or
                      make it anonymous by removing all details that identify you.

                      However, if necessary, we may retain your personal information for our compliance with a legal,
                      accounting, or reporting obligation or for archiving purposes in the public interest, scientific,
                      or historical research purposes or statistical purposes.
                    </p>
                    <h3>Children’s Privacy</h3>
                    <p>
                      We do not aim any of our products or services directly at children under the age of 18 and we do not
                      knowingly collect personal information about children under 18.
                    </p>
                    <h3>Disclosure of Personal Information to Third Parties</h3>
                    <p>
                      We may disclose personal information to:

                      an affiliate of our company,

                      third-party service providers for the purpose of enabling them to provide their services including,
                      (without limitation) IT service providers, data storage, hosting and server providers, ad networks,
                      analytics, error loggers, debt collectors, maintenance or problem-solving providers,
                      professional advisors, and payment systems operators,

                      our employees, contractors, and/or related entities,

                      our existing or potential agents or business partners,

                      credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail
                      to pay for goods or services we have provided to you,

                      courts, tribunals, regulatory authorities, and law enforcement officers, as required by law,
                      in connection with any actual or prospective legal proceedings, or in order to establish,
                      exercise, or defend our legal rights,

                      third parties, including agents or sub-contractors who assist us in providing information,
                      products, services, or direct marketing to you,

                      third parties to collect and process data,

                      an entity that buys, or to which we transfer all or substantially all of our assets and business,

                      Third parties we currently use include:

                      Google Payments,
                      Apple Pay,
                      RevenueCat,
                      expo.dev,
                      CloudFlare,
                      Digital Ocean,
                      MongoDB Atlas,
                      SendGrid,
                      SMS123,
                      Zoho Desk,
                      Google for Business
                    </p>
                    <h3>Your Rights and Controlling Your Personal Information</h3>
                    <h3>Your choice:</h3>
                    <p>
                      By providing personal information to us, you understand we will collect, hold, use, and disclose
                      your personal information in accordance with this privacy policy. You do not have to provide personal
                      information to us, however, if you do not, it will affect your use of our app or the products and/or
                      services offered on or through it.
                    </p>
                    <h3>Information from third parties:</h3>
                    <p>
                      If we receive personal information about you from a third party, we will protect it as set out in
                      this privacy policy. If you are a third party providing personal information about somebody else,
                      you represent and warrant that you have such person’s consent to provide the personal information to us.
                    </p>
                    <h3>Marketing permission:</h3>
                    <p>
                      If you have previously agreed to us using your personal information for direct marketing purposes,
                      you may change your mind at any time by contacting us using our contact details below.
                    </p>
                    <h3>Access:</h3>
                    <p>
                      You may request details of the personal information that we hold about you, and in order for us to
                      process your request, you will need to email us a copy of your IC and a selfie so we can verify it is you.
                      It may take us up to 30 days to process your request and we will send you your data via email.
                    </p>
                    <h3>Correction:</h3>
                    <p>
                      If you believe that any information we hold about you is inaccurate, incorrect, out of date, incomplete,
                      irrelevant, or misleading, please use our app and correct your information yourself.
                      We do not chage any personal information on behalf of our users.
                    </p>
                    <h3>Non-discrimination: </h3>
                    <p>
                      We will not discriminate against you for exercising any of your rights over your personal information.
                      Unless your personal information is required to provide you with a particular service or offer
                      (for example serving particular content to your device), we will not deny you goods or services
                      and/or charge you different prices or rates for goods or services, including through granting
                      discounts or other benefits, or imposing penalties, or provide you with a different level or
                      quality of goods or services.
                    </p>
                    <h3>Notification of data breaches:</h3>
                    <p>
                      We will comply with laws applicable to us in respect of any data breach.
                    </p>
                    <h3>Complaints:</h3>
                    <p>
                      If you believe that we have breached a relevant data protection law and wish to make a complaint,
                      please contact us using the details below and provide us with full details of the alleged breach.
                      We will promptly investigate your complaint and respond to you, in writing, setting out the outcome
                      of our investigation and the steps we will take to deal with your complaint. You also have the right
                      to contact a regulatory body or data protection authority in relation to your complaint.
                    </p>
                    <h3>Unsubscribe:</h3>
                    <p>
                      To unsubscribe from our email database or opt-out of communications (including marketing communications),
                      please contact us using the details provided in this privacy policy, or opt-out using the opt-out facilities
                      provided in the communication. We may need to request specific information from you to help us confirm your identity.
                    </p>
                    <h3>Business Transfers</h3>
                    <p>
                      If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy,
                      we would include data, including your personal information, among the assets transferred to any parties who
                      acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may, to the
                      extent permitted by applicable law, continue to use your personal information according to this policy,
                      which they will be required to assume as it is the basis for any ownership or use rights we have over
                      such information.
                    </p>
                    <h3>Limits of Our Policy</h3>
                    <p>
                      Our app may link to external sites that are not operated by us. Please be aware that we have no
                      control over the content and policies of those sites, and cannot accept responsibility or liability
                      for their respective privacy practices.
                    </p>
                    <h3>Changes to This Policy</h3>
                    <p>
                      At our discretion, we may change our privacy policy to reflect updates to our business processes,
                      current acceptable practices, or legislative or regulatory changes. If we decide to change this
                      privacy policy, we will post the changes here.

                      If the changes are significant, or if required by applicable law, we will contact you
                      (based on your selected preferences for communications from us) and all our registered users
                      with the new details and links to the updated or changed policy.

                      If required by law, we will get your permission or give you the opportunity to opt in to or opt out of,
                      as applicable, any new uses of your personal information.
                    </p>
                    <h3>Contact Us</h3>
                    <p>
                      For any questions or concerns regarding your privacy, you may contact us using the following details:
                    </p>
                    <p>
                      MingleSea Support
                      <br />
                      support@minglesea.com
                    </p>

                  </div>



                  <div id="opt2">
                    <h2 className="font-gilroy-bold">Terms of Service</h2>
                    <div className="update-date" style={{ paddingLeft: '5px' }}>LAST UPDATED: 2024/06/01</div>
                    <p>
                      These Terms of Service govern your use of MingleSea, our website located at{" "}
                      <a href="https://minglesea.com">https://minglesea.com</a> and any related services provided by MingleSea.
                    </p>
                    <p>
                      When you create a MingleSea account or use MingleSea, you agree to abide by these Terms of Service and
                      to comply with all applicable laws and regulations. If you do not agree with these Terms of Service,
                      you are prohibited from further using the app, accessing our website, or using any other services provided
                      by MingleSea.
                    </p>
                    <p>
                      If you access or download MingleSea from (1) the Apple App Store, you agree to any Usage Rules set forth
                      in the App Store Terms of Service; and/or (2) the Google Play Store, you agree to the Android, Google Inc.
                      Terms and Conditions including the Google Apps Terms of Service.
                    </p>
                    <p>We, MingleSea, reserve the right to review and amend any of these Terms of Service at our sole discretion.
                      Upon doing so, we will update this page and may and notify you through the app and/or the email address you provided
                      when you created your account. Any changes to these Terms of Service will take effect immediately from the date
                      of publication.
                    </p>
                    <h3>Limitations of Use</h3>
                    <p>
                      By using MingleSea and our website, you warrant on behalf of yourself, any entity who you represent who has entered
                      into these Terms of Service, that you will not:

                      modify, copy, prepare derivative works of, decompile, or reverse engineer MingleSea or any materials and software
                      contained within MingleSea or on our website;

                      remove any copyright or other proprietary notations from MingleSea or any materials and software contained within
                      MingleSea or on our website;

                      transfer MingleSea or any of its associated materials to another person or "mirror" the materials on any other server;

                      knowingly or negligently use MingleSea or any of its associated services in a way that abuses or disrupts our networks
                      or any other service MingleSea provides;

                      use MingleSea or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent,
                      or unlawful material;

                      use MingleSea or its associated services in violation of any applicable laws or regulations;

                      use MingleSea to send unauthorized advertising or spam;

                      harvest, collect, or gather user data with or without the user’s consent; or

                      use MingleSea or its associated services in such a way that may infringe the privacy, intellectual property rights,
                      or other rights of third parties.
                    </p>
                    <h3>Intellectual Property</h3>
                    <p>
                      The intellectual property in the materials in MingleSea and on our website are owned by or licensed to MingleSea.
                      You may download MingleSea to view, use, and display the application on your mobile device for your personal use only.

                      This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you
                      violate any of these restrictions or these Terms of Service and can be terminated by MingleSea at any time without any prior notice.
                    </p>
                    <h3>User-Generated Content</h3>
                    <p>
                      You retain your intellectual property ownership rights over content you submit to us for publication within MingleSea
                      and/or on its corresponding website. We will never claim ownership of your content but we do require a license from
                      you in order to use it.

                      When you use MingleSea or its associated services to post, upload, share or otherwise transmit content covered by
                      intellectual property rights, you grant to us a non-exclusive, royalty-free, transferable, sub-licensable,
                      worldwide license to use, distribute, modify, run, copy, publicly display, translate or otherwise create
                      derivative works of your content in a manner that is consistent with your privacy preferences and our Privacy Policy.

                      The license you grant us can be terminated at any time by deleting your content or account. However, to the extent
                      that we (or our partners) have used your content in connection with commercial or sponsored content, the license
                      will continue until the relevant commercial or post has been discontinued by us.

                      You give us permission to use your username, phone number, email address and other identifying information associated with your account in
                      a manner that is consistent with your privacy preferences and our Privacy Policy.
                    </p>
                    <h3>Automatic Updates</h3>
                    <p>
                      You give us permission to download and install updates to MingleSea on your device in accordance
                      with your privacy preferences. This permission can be revoked at any time by deleting MingleSea
                      from your device.
                    </p>
                    <h3>Liability</h3>
                    <p>
                      MingleSea and the materials in MingleSea and on our website are provided on an 'as is' basis. To the extent
                      permitted by law, MingleSea makes no warranties, expressed or implied, and hereby disclaims and negates all
                      other warranties including, without limitation, implied warranties or conditions of merchantability, fitness
                      for a particular purpose, or non-infringement of intellectual property, or other violation of rights.

                      In no event shall MingleSea or its suppliers be liable for any consequential loss suffered or incurred by you
                      or any third party arising from the use or inability to use MingleSea, our website, or any other services
                      provided by MingleSea or the materials in MingleSea, even if MingleSea or an authorized representative has
                      been notified, orally or in writing, of the possibility of such damage.

                      In the context of this agreement, "consequential loss" includes any consequential loss, indirect loss,
                      real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill,
                      loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data,
                      whether under statute, contract, equity, tort (including negligence), indemnity or otherwise.

                      Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability
                      for consequential or incidental damages, these limitations may not apply to you.
                    </p>
                    <h3>Accuracy of Materials</h3>
                    <p>
                      The materials appearing in MingleSea or on our website are not comprehensive and are for general information
                      purposes only. To the extent permitted by law, MingleSea does not warrant or make any representations
                      concerning the accuracy, likely results, or reliability of the use of the materials in MingleSea or on
                      our website, or otherwise relating to such materials or on any resources linked to MingleSea and our website.
                    </p>
                    <h3>Links</h3>
                    <p>
                      MingleSea has not reviewed all of the sites linked to MingleSea or on its corresponding website
                      and is not responsible for the contents of any such linked site. The inclusion of any link
                      does not imply endorsement, approval or control by MingleSea of the site. Use of any such linked
                      website is at your own risk and we strongly advise you make your own investigations with respect
                      to the suitability of those sites.
                    </p>
                    <h3>Notice regarding Apple</h3>
                    <p>
                      To the extent that you are using or accessing MingleSea on an iOS device, you acknowledge
                      and agree to the terms of this clause. You acknowledge that these Terms of Service are between
                      you and MingleSea only, not with Apple Inc. (Apple), and Apple is not responsible for MingleSea
                      and any materials available in MingleSea.

                      Apple has no obligation to furnish you with any maintenance and support services with respect to MingleSea.

                      If MingleSea fails to conform to any applicable warranty, you may notify Apple and Apple will refund the
                      purchase price of the mobile application to you. To the maximum extent permitted by applicable law,
                      Apple will have no other warranty obligation whatsoever with respect to MingleSea and any other claims,
                      losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty
                      will be our responsibility.

                      Apple is not responsible for addressing any claims by you or any third party relating to MingleSea
                      or your use of MingleSea, including but not limited to (1) product liability claims; (2) any claim
                      that our mobile application fails to conform to any applicable legal or regulatory requirement;
                      and (3) claims arising under consumer protection or similar legislation.

                      Apple is not responsible for the investigation, defense, settlement and discharge of any third-party
                      claim that our mobile application infringes that third party’s intellectual property rights.

                      You agree to comply with any applicable third-party terms when using MingleSea,
                      including any Usage Rules set forth in the Apple App Store Agreement of Service.

                      Apple and Apple’s subsidiaries are third-party beneficiaries of these Terms of Service and,
                      upon your acceptance of these Terms of Service, Apple will have the right
                      (and will be deemed to have accepted the right) to enforce these Terms of Service
                      against you as a third-party beneficiary of these Terms of Service.

                      You hereby represent and warrant that (1) you are not located in a country that is subject
                      to a U.S. Government embargo, or that has been designated by the U.S. Government as a 'terrorist supporting'
                      country; and (2) you are not listed on any U.S. Government list of prohibited or restricted parties.
                    </p>
                    <h3>Emotional Well-Being</h3>
                    <p> MingleSea shall not be held responsible or liable for any emotional distress,
                      trauma, or psychological harm that may result from the use of our app. By using MingleSea, you acknowledge and
                      agree that your emotional well-being is your own responsibility, and that MingleSea makes no guarantees or warranties
                      regarding the emotional impact of using the app, including but not limited to, interactions with other users,
                      the content displayed, or the outcomes of using our services. In no event shall MingleSea be liable for any claims
                      related to emotional harm, whether direct, indirect, incidental, or consequential, arising out of your use or
                      inability to use the app.
                    </p>
                    <h3>User Interactions Outside of MingleSea</h3>
                    <p>
                      We take no responsibility and disclaim any liability for any physical or emotional harm, damages, or losses
                      that may occur if users choose to engage with each other outside of the MingleSea platform. Any in-person
                      meetings, communications, or other forms of engagement outside of the app are solely at the users' own risk.
                      MingleSea does not screen, verify, or endorse any user-generated interactions beyond the app's confines and
                      will not be liable for any outcomes resulting from such interactions, whether they be direct, indirect,
                      incidental, or consequential.
                    </p>
                    <h3>Right to Terminate</h3>
                    <p>
                      We reserve the right to suspend, terminate, block or delete your MingleSea account and revoke your rights to
                      use MingleSea and these Terms of Service at any time, immediately and without written notice to you for any breach of
                      these Terms of Service or for any other reason we see fit.
                    </p>
                    <h3>Refund Policy</h3>
                    <p>
                      Notwithstanding any circumstances, we will not provide any refunds for services purchased through Minglesea.
                    </p>
                    <h3>Severance</h3>
                    <p>
                      Any term of these Terms of Service which is wholly or partially void or unenforceable is
                      severed to the extent that it is void or unenforceable. The validity of the remainder of
                      these Terms of Service is not affected.
                    </p>
                    <h3>Governing Law</h3>
                    <p>
                      These Terms of Service are governed by and construed in accordance with the laws of any country we operate in.
                      You irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                    </p>

                  </div>


                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <footer className="theme-footer-seven mt-20 md-mt-0">
        <div className="lg-container">
          {/* <div className="container">
            <FooterSeven />
          </div> */}
          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default TermsConditions;
