import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AllRoutes from "./router/AllRoutes";
// import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";

import { useLocation } from 'react-router-dom';

const App = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    
    
    AOS.init({
      duration: 1200,
    });

    window.scrollTo(0, 0);

  }, [pathname]);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
      <Helmet>
        <title>MingleSea - The Magical Dating App</title>
        <meta property="og:site_name" content="MingleSea" />
        <meta
          property="og:url"
          content="https://minglesea.com"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="MingleSea, The Magical Dating App"
        />
        <meta
          name="keywords"
          content="Online Dating, #TheMagicalDatingApp"
        />
        <meta
          name="description"
          content="The Magical Dating App"
        />
        <meta name="description" content="The Magical Dating App" />
      </Helmet>
      {/* End Seo Helmt */}

      {/* <ScrollToTop /> */}
      {/* End Scroll top */}



      <AllRoutes />
    </>
  );
};

export default App;
