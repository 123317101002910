import React from "react";

import one from '../../assets/images/new/1.png';
import two from '../../assets/images/new/2.png';
import three from '../../assets/images/new/3.png';
import four from '../../assets/images/new/4.png';
import five from '../../assets/images/new/5.png';
import six from '../../assets/images/new/6.png';
import seven from '../../assets/images/new/7.png';
import eight from '../../assets/images/new/8.gif';
import nine from '../../assets/images/new/9.png';
import ten from '../../assets/images/new/10.png';

import { getEnvVars } from '../../env';
const ENV = getEnvVars(process.env.REACT_APP_ENV);



//import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// const tabList = [
//   { iconOne: "105", iconTwo: "105-c" },
//   { iconOne: "106", iconTwo: "106-c" },
//   { iconOne: "107", iconTwo: "107-c" },
//   { iconOne: "108", iconTwo: "108-c" },
//   { iconOne: "109", iconTwo: "109-c" },
//   { iconOne: "110", iconTwo: "110-c" },
//   { iconOne: "111", iconTwo: "111-c" },
// ];

const tabListContent = [
  {
    initials: 'F',
    img: one,
    innerText: "Free",
    title: "Free ",
    description: `MingleSea is, and will always remain free for Women. Only Men pay on MingleSea, $${ENV.REACT_APP_MATCH_QUOTA_VALUE_USD}.00 USD per match.`,
  },
  {
    initials: 'S',
    img: two,
    innerText: "Serious",
    title: "Serious",
    description: `In MingleSea, you need to be serious about whatever it is you are looking for, because everything here has a fixed limit here.`,
  },
  {
    initials: 'P',
    img: three,
    innerText: "PassLike",
    title: "PassLike",
    description: `In MingleSea, users PassLike instead of swipe.
    What's the difference? There is a fixed, daily limit, and you can't buy more PassLikes. Every 24 hours you get a total of ${ENV.REACT_APP_DAILY_TOTAL_PASS_LIKE_REPORT} PaassLikes which includes only ${ENV.REACT_APP_DAILY_TOTAL_LIKE} likes.`,
  },
  {
    initials: 'J',
    img: four,
    innerText: "Junk Swipe",
    title: "Junk Swipe",
    description: `With PassLikes, everyone is going to take good look at your profile before they decide if they like you or not. Junk swipes won't work here because of our daily limit.`,
  },
  {
    initials: 'A',
    img: five,
    innerText: "Active Matches",
    title: "Active Matches",
    description: `When you match with someone, they stay in your matches page for exactly 24 hours and there is absolutely no way to extend that time limit.`,
  },
  {
    initials: 'T',
    img: six,
    innerText: "Chat Time",
    title: "Chat Time",
    description: `When you match with someone, you can chat with them for only 24 hours. ${ENV.REACT_APP_MESSAGES_PER_CHAT} messages, maximum ${ENV.REACT_APP_CHARACTERS_PER_MESSAGE} characters each.
    Hopefully after that you go on a date and get married.`,
  },
  {
    initials: 'L',
    img: seven,
    innerText: "Active Match Limit",
    title: "Active Match Limit",
    description: `In MingleSea, we allow you to have a maximum of 2 active matches in your matches page if you are a man, and 1 if you are a woman.`,
  },
  {
    initials: 'N',
    img: ten,
    innerText: "90 Days",
    title: "90 Days",
    description: `You have exactly ${ENV.REACT_APP_NINETY_DAYS_DELETE} days from the time we verify your profile to find someone on MingleSea. After the ${ENV.REACT_APP_NINETY_DAYS_DELETE} days we will delete your account and you can't use or rejoin MingleSea for ${ENV.REACT_APP_DELETED_DELETE} days.`,
  },
  {
    initials: 'M',
    img: nine,
    innerText: "Active Hide",
    title: "Active Hide",
    description: `When you reach your Active Match Limit, we hide your profile from other users because we hope you can click with yor match, leave MingleSea, and never come back.`,
  },
  {
    initials: 'I',
    img: eight,
    innerText: "MingleAI",
    title: "Artificial Intelligence",
    description: `MingleAI is our state of the art AI model which we use to hand select the magical suggestions we show you, one by one.`,
  },
];

const DocEditorTabs = () => {
  return (
    <>
      {/* <Tabs> */}
      {/* <TabList>
          {tabList.map((img, i) => (
            <Tab key={i}>
              <div className="nav-link d-flex align-items-center justify-content-center">
                <span style={{ fontSize: '2em', fontWeight: 'bold' }}>
                  {tabListContent[i].initials}
                </span>
              </div>
            </Tab>
          ))}
        </TabList> */}

      <div className="pt-40 sm-pt-10 tab-content">
        {tabListContent.map((tabContent, i) => (
          // <TabPanel key={i}>
          <div key={i} className="row no-gutters align-items-center mt-60" style={{ backgroundColor: 'white', borderRadius: '10px' }}>
            <div className="col-md-6">
              <div className="img-holder">
                <img
                  src={tabContent.img}
                  alt="media"
                />
                <div className="ripple-box d-flex align-items-center justify-content-center">
                  <p>{tabContent.innerText}</p>
                </div>
              </div>{" "}
              {/* /.img-holder */}
            </div>
            <div className="col-md-6">
              <div className="text-wrapper">
                <h4>{tabContent.title}</h4>
                <p>{tabContent.description}</p>
              </div>
              {/* /.text-wrapper */}
            </div>
          </div>
          // </TabPanel>
        ))}
      </div>

      {/* /.pricing-table-area-six */}
      {/* </Tabs> */}
    </>
  );
};

export default DocEditorTabs;
