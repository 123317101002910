import React from "react";
import { Link } from "react-router-dom";

const socialContent = [
  {
    icon: "fa-heart",
    // link: "https://www.facebook.com/",
  },
  // {
  //   icon: "fa-instagram",
  //   link: "https://www.linkedin.com/",
  // },
  // {
  //   icon: "fa-twitter",
  //   link: "https://twitter.com/",
  // },
];

const CopyRightThree = () => {
  return (
    <div className="row">
      <div className="col-lg-4 order-lg-1 mb-20">
        <ul
          className="
              d-flex
              justify-content-center justify-content-lg-start
              footer-nav
            "
        >
          <li style={{ zIndex: 10 }}>
            <Link style={{ textDecoration: 'underline' }} to="/pnt">Privacy & Terms</Link>
          </li>
          {/* <li>
            <Link to="/contact">Contact Us</Link>
          </li> */}
        </ul>
      </div>
      <div className="col-lg-4 order-lg-3 mb-20">
        <ul
          className=" d-flex
              justify-content-center justify-content-lg-end
              social-icon"
        >
          {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                {/* <i className={`fa ${val.icon}`} style={{color:'#006496'}}></i> */}
                <p className="copyright" style={{ color: "#000" }}>{'#TheMagicalDatingApp'}</p>
              </a>
            </li>
          ))}
        </ul>
        {/* End .social-icon */}
      </div>
      <div className="col-lg-4 order-lg-2 mb-20">
        <p className="copyright text-center">
          © {new Date().getFullYear()}{" "}MingleSea, All Rights Reservesd.

        </p>
        <p className="copyright text-center">

        </p>
      </div>
    </div>
  );
};

export default CopyRightThree;
