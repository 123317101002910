import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
//import MegaMenuLanding from "../../header/mega-menu/MegaMenuLanding";

const HeaderLandingEditor = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {/* =============================================
				Theme Main Menu
			==============================================  */}
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-five fixed"
            : "theme-main-menu sticky-menu theme-menu-five"
        }
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
            <Link to="/">
              <div className="d-flex justify-content-left align-items-center">
                <img src="logo.png" alt="brand" style={{ width: '50px', height: 'auto' }} />
                <h4 style={{ fontSize: '30px', fontFamily: 'gordita', color: '#004594', marginLeft: '10px', fontWeight: '400', marginTop:'4px' }}>MingleSea</h4>
              </div>
            </Link>


          </div>
          {/* End logo */}

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div className="mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                  <div className={click ? "active" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
              {/* End Header */}

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={[
                      // "home",
                      // "why",
                      // "ppm",
                      // "algo",
                      // "here",
                    ]}
                    currentClassName="active"
                    offset={-90}
                  >
                    {/* <li className="nav-item dropdown position-static">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#home"
                        data-toggle="dropdown"
                      >
                        Home
                      </a>
                      <div className="dropdown-menu">
                        <MegaMenuLanding />
                      </div>
                    </li> */}

                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        Home
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <a href="#why" className="nav-link">
                        Why Us?
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href="#ppm" className="nav-link">
                        PPM
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#algo" className="nav-link">
                        Our Algo
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#here" className="nav-link">
                        Not Here
                      </a>
                    </li> */}



                    <li className="nav-item">
                      <Link to="/ros" className="nav-link">
                        ROS
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/faq" className="nav-link">
                        FAQ
                      </Link>
                    </li>


                    <li className="nav-item">
                      <Link to="/contact" className="nav-link">
                        Contact Us
                      </Link>
                    </li>


                    {/* <li className="nav-item">
                      <Link to="/social" className="nav-link">
                        Social
                      </Link>
                    </li> */}

                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
          {/* <div className="right-widget">
            <ul className="d-flex align-items-center">
              <li>
                <Link
                  to="/login"
                  className="signIn-action d-flex align-items-center"
                >
                  <img src="images/icon/52.svg" alt="icon" />
                  <span>login</span>
                </Link>
              </li>
              <li>
                <Link to="/signup" className="signup-btn">
                  <span>Sign up</span>- It’s Free
                </Link>
              </li>
            </ul>
          </div> */}
          {/* End .right-widget */}
        </div>
      </div>
      {/* /.theme-main-menu */}

      {/* Mobile Menu Start */}
      <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
        <div className="logo order-md-1">
          <Link to="/">
            {/* <img src="images/logo/deski_06.svg" alt="brand" /> */}

            <div className="d-flex justify-content-left align-items-center">
              <img src="logo.png" alt="brand" style={{ width: '40px', height: 'auto' }} />
              <h4 style={{ fontSize: '25px', fontFamily: 'gordita', color: '#004594', marginLeft: '10px', fontWeight: '400', marginTop:'4px'  }}>MingleSea</h4>

            </div>
          </Link>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="images/icon/close.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}
        </div>

        <Scrollspy
          className="navbar-nav"
          id="theme-menu-list"
          // items={["home", "feature", "effect", "template", "feedback"]}
          currentClassName="active"
          offset={-90}
        >
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Home
            </Link>
          </li>

          {/* <li className="nav-item">
            <a href="#why" className="nav-link" onClick={handleClick}>
              Why Us?
            </a>
          </li>
          <li className="nav-item">
            <a href="#ppm" className="nav-link" onClick={handleClick}>
              PPM
            </a>
          </li>
          <li className="nav-item">
            <a href="#algo" className="nav-link" onClick={handleClick}>
              Our Algo
            </a>
          </li>
          <li className="nav-item">
            <a href="#here" className="nav-link" onClick={handleClick}>
              Not Here
            </a>
          </li> */}



          <li className="nav-item">
            <Link to="/ros" className="nav-link">
              ROS
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/faq" className="nav-link">
              FAQ
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/contact" className="nav-link">
              Contact Us
            </Link>
          </li>

          {/* <li className="nav-item">
            <Link to="/social" className="nav-link">
              Social
            </Link>
          </li> */}

        </Scrollspy>
      </div>
      {/* Mobile Menu End */}
    </>
  );
};

export default HeaderLandingEditor;
