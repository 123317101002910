import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>Not Found || Deski-Saas & Software React Template</title>
      </Helmet>
      {/* End Page SEO Content */}

      <div className="error-page d-lg-flex align-items-center">
        <div className="img-holder order-lg-last">
          <img
            src="images/media/404.svg"
            alt="media"
            className="w-100 illustration"
          />
          <img
            src="images/media/404-q.svg"
            alt="media"
            className="shapes qus"
          />
        </div>
        <div className="text-wrapper order-lg-first">
          <div className="logo">
            <Link to="/">
              <div className="d-flex justify-content-left align-items-center">
                <img src="logo.png" alt="brand" style={{ width: '50px', height: 'auto' }} />
                <h4 style={{ fontSize: '30px', fontFamily: 'gordita', color: '#004594', marginLeft: '10px', fontWeight: '400' }}>MingleSea</h4>
              </div>
            </Link>
          </div>
          <h1 className="font-slab ">
            Sorry, It's not here
          </h1>
          <p className="font-rubik">
            We still love you though ...
          </p>

          <Link
            to="/"
            className="back-home font-rubik d-flex align-items-center justify-content-center"
          >
            <span>Back to Home</span>
            <img src="images/icon/53.svg" alt="icon" />
          </Link>
        </div>
        {/* /.text-wrapper */}
      </div>
      {/* /.error-page */}
    </div>
  );
};

export default NotFound;
